var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.isIE
    ? _c(
        "div",
        { attrs: { id: "app" } },
        [
          _vm.blockAccess ? _c("delinquent") : _vm._e(),
          _c("global-loader", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLoading && !_vm.hasError && !_vm.blockAccess,
                expression: "isLoading && !hasError && !blockAccess",
              },
            ],
          }),
          !_vm.isLoading && _vm.hasError && !_vm.blockAccess
            ? _c("global-error", {
                attrs: {
                  showLogo: _vm.componentIsEnabled("globalError", "logo"),
                  code: _vm.errorCode,
                  theme: _vm.theme,
                },
              })
            : !_vm.isLoading && !_vm.hasError && !_vm.blockAccess
            ? [
                _c(
                  "transition",
                  { attrs: { name: "fade", mode: "out-in" } },
                  [_c("router-view")],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }