var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("emd-footer-internal", {
    attrs: {
      switchLanguage: _vm.switchLanguage,
      switchThemeMode: _vm.switchThemeMode,
      privacySecurity: _vm.privacySecurity,
      help: _vm.help,
      termsOfUse: _vm.termsOfUse,
      privacyPolicy: _vm.privacyPolicy,
      secureEnv: _vm.secureEnv,
      documentation: _vm.documentation,
      status: _vm.status,
      listStyle: _vm.listStyle,
    },
    on: {
      changeLanguage: _vm.changeLanguage,
      changeThemeMode: _vm.changeThemeMode,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }