import actionsPermissionIsEnabled from '.'
import { isStaffType, MERCHANT_TYPES } from '@/enums/merchant-type.enum'

export const INVITES_ACTIONS_ENUM = {
  view: 'merchant_staff.invite:view',
  search: 'merchant_staff.invite:search',
  get: 'merchant_staff.invite:get',
  create: 'merchant_staff.invite:create'
}

const INVITES_ACTIONS = Object.values(INVITES_ACTIONS_ENUM)

const invitesEnabled = permissionIsEnabledFn =>
  actionsPermissionIsEnabled(INVITES_ACTIONS, permissionIsEnabledFn)

const invitesViewEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(INVITES_ACTIONS_ENUM.view)

const invitesSearchEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(INVITES_ACTIONS_ENUM.search)

const invitesSearchEnabledAndIsNotStaff = (permissionIsEnabledFn, { key: merchantType } = MERCHANT_TYPES.staff) =>
  invitesSearchEnabled(permissionIsEnabledFn) && !isStaffType(merchantType)

const invitesGetEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(INVITES_ACTIONS_ENUM.get)

const invitesGetEnabledAndIsNotStaff = (permissionIsEnabledFn, { key: merchantType } = MERCHANT_TYPES.staff) =>
  invitesGetEnabled(permissionIsEnabledFn) && !isStaffType(merchantType)

const invitesCreateEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(INVITES_ACTIONS_ENUM.create)

const invitesCreateEnabledAndIsNotStaff = (permissionIsEnabledFn, { key: merchantType } = MERCHANT_TYPES.staff) =>
  invitesCreateEnabled(permissionIsEnabledFn) && !isStaffType(merchantType)

export {
  invitesViewEnabled,
  invitesSearchEnabled,
  invitesGetEnabled,
  invitesCreateEnabled,
  invitesSearchEnabledAndIsNotStaff,
  invitesCreateEnabledAndIsNotStaff,
  invitesGetEnabledAndIsNotStaff
}

export default invitesEnabled
