import { dateFormat } from '@/filters/index.js'

class UsersManager {
  data = []

  constructor (data) {
    this.data = data
  }

  /**
   *
   * @param {*} user
   * @returns
   */
  convertToView (user) {
    const {
      id,
      first_name,
      last_name,
      email,
      phone,
      document_number: documentNumber,
      merchant,
      created_at,
      updated_at
    } = user

    return {
      id,
      // eslint-disable-next-line camelcase
      name: first_name && last_name ? `${first_name} ${last_name}` : '-' ,
      first_name,
      last_name,
      email: email || '-',
      merchant,
      phone: phone || '-',
      document_number: documentNumber || '-',
      created_at: dateFormat(created_at, 'date'),
      updated_at: dateFormat(updated_at, 'date')
    }
  }

  /**
   *
   * @param {*} dataDefault
   * @returns
   */
  adapter (dataDefault) {
    return dataDefault.map(invite => {
      return this.convertToView(invite)
    })
  }

  /**
   * Executes all the methods
   * @returns Array
   */
  get () {
    return this.adapter(this.data)
  }

  getOne () {
    if (!this.data.length) throw new Error('Missing argument data!')
    return this.get()[0]
  }
}

export default UsersManager
