var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "emd-btn-menu _color_secondary",
      class: { _open: _vm.btnMenuState },
      on: { click: _vm.toogleBtnMenu },
    },
    [
      _c("span", { staticClass: "emd-action-button" }, [
        _c(
          "svg",
          {
            staticClass: "emd-action-button__icon",
            attrs: {
              overflow: "visible",
              width: "14px",
              height: "16px",
              viewBox: "0 0 24 20",
              version: "1.1",
              xmlns: "http://www.w3.org/2000/svg",
              "xmlns:xlink": "http://www.w3.org/1999/xlink",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M0,19 L26,19",
                id: "down",
                "stroke-width": "4",
                "stroke-linecap": "round",
              },
            }),
            _c("path", {
              attrs: {
                d: "M0,10 L26,10",
                id: "middle",
                "stroke-width": "4",
                "stroke-linecap": "round",
              },
            }),
            _c("path", {
              attrs: {
                d: "M0,1 L26,1",
                id: "up",
                "stroke-width": "4",
                "stroke-linecap": "round",
              },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }