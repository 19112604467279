export const SETTINGS_FUNCTIONALITIES_ACTIONS = {
  view: 'settings.functionalities:view',
  edit: 'settings.functionalities:edit'
}

export const SETTINGS_INFO_ACTIONS = {
  get: 'settings.info:get',
  edit: 'settings.info:edit'
}

export const SETTINGS_GENERAL_DATA_ACTIONS = {
  view: 'settings.general_data:view',
  edit: 'settings.general_data:edit'
}


export const settingsFunctionalitiesViewEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(SETTINGS_FUNCTIONALITIES_ACTIONS.view)

export const settingsFunctionalitiesEditEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(SETTINGS_FUNCTIONALITIES_ACTIONS.edit)

export const settingsInfoGetEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(SETTINGS_INFO_ACTIONS.get)

export const settingsInfoEditEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(SETTINGS_INFO_ACTIONS.edit)

export const settingsGeneralDataViewEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(SETTINGS_GENERAL_DATA_ACTIONS.view)

export const settingsGeneralDataEditEnabled = permissionIsEnabledFn =>
  permissionIsEnabledFn(SETTINGS_GENERAL_DATA_ACTIONS.edit)