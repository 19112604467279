var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "el-core__header__left" }, [
    _c(
      "div",
      { staticClass: "emd-sidemenu__trigger" },
      [
        _c("emd-button-menu", {
          attrs: { btnMenuState: _vm.isOpen },
          on: { btnMenuClicked: _vm.toggleAppMenu },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "emd-control",
        class: _vm.statusClassList,
        attrs: { "data-onboarding": "navigation" },
      },
      [
        _c(
          "div",
          { staticClass: "emd-control__wrapper" },
          [
            _c("span", { staticClass: "emd-control__status" }, [
              _vm._v(" " + _vm._s(_vm.$t(_vm.status.label)) + " "),
            ]),
            _c("div", { staticClass: "emd-control__link" }, [
              _vm._m(0),
              _vm.merchantName
                ? _c(
                    "a",
                    {
                      staticClass: "emd-control__text",
                      attrs: {
                        href: "javascript:void(0);",
                        title: _vm.merchantName,
                      },
                    },
                    [
                      _c("span", { staticClass: "emd-control__text__item" }, [
                        _vm._v(" " + _vm._s(_vm.merchantName) + " "),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]),
            _c(
              "emd-tippy",
              {
                staticClass: "_no-wrap",
                attrs: {
                  id: "merchant",
                  type: "ghost",
                  position: "left",
                  content: _vm.$t("tooltip.merchant"),
                },
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "emd-action-button _has-tooltip _type_ghost",
                    attrs: {
                      "data-tippy-content": _vm.$t("tooltip.merchant"),
                      "aria-expanded": "false",
                    },
                    on: { click: _vm.contextAnchorMerchant },
                  },
                  [
                    _c("i", {
                      staticClass: "emd-action-button__icon uil uil-exchange",
                      attrs: { "fixed-width": "", transform: "width-16" },
                    }),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]
    ),
    _vm._m(1),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "emd-control__icon" }, [
      _c("i", {
        staticClass: "uil uil-building",
        attrs: { transform: "width-20" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "eu-ml_4 eu-display_none eu-display_block_md" },
      [
        _c("div", { staticClass: "emd-logo-custom" }, [
          _c("div", { staticClass: "emd-logo-custom__image" }),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }