var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "emd-menu", class: { _simple: _vm.simple } },
    [
      _c(
        "main",
        { staticClass: "emd-menu__main" },
        [
          _vm._l(_vm.menu, function (section, key) {
            return [
              section.showSection
                ? _c(
                    "router-link",
                    {
                      key: key,
                      staticClass: "emd-menu__section",
                      attrs: {
                        "active-class": "_active",
                        to: "/" + _vm.merchantId + "/" + key,
                        id: key,
                      },
                    },
                    [
                      _c("section", { staticClass: "emd-menu__section" }, [
                        _c("a", { attrs: { href: "javascript:void(0)" } }, [
                          _c("span", { staticClass: "emd-menu__icon" }, [
                            _c("i", { class: ["uil", "uil-" + section.icon] }),
                          ]),
                          _c(
                            "span",
                            { staticClass: "emd-menu__label titleCase" },
                            [_vm._v(_vm._s(_vm.$t("ui.appMenu." + key)))]
                          ),
                          section.demo
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "emd-tag _color_info eu-ml_2 eu-display_none eu-display_inline_md",
                                },
                                [_vm._v("Demo")]
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          }),
          _vm.statisticsAvailability && _vm.permissionIsEnabled("finance:view")
            ? _c("section", { staticClass: "emd-menu__section" }, [
                _c("a", { attrs: { href: _vm.$sanitize(_vm.urlFinance) } }, [
                  _c("span", { staticClass: "emd-menu__icon" }, [
                    _c("i", { class: ["uil", "uil-chart-bar"] }),
                  ]),
                  _c("span", { staticClass: "emd-menu__label titleCase" }, [
                    _vm._v(_vm._s(_vm.$t("ui.appMenu.finance"))),
                  ]),
                ]),
              ])
            : _vm._e(),
          _c("section", { staticClass: "emd-menu__section" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.$sanitize(_vm.urlHelpCenter),
                  target: "_blank",
                },
              },
              [
                _c("span", { staticClass: "emd-menu__icon" }, [
                  _c("i", { class: ["uil", "uil-question-circle"] }),
                ]),
                _c("span", { staticClass: "emd-menu__label titleCase" }, [
                  _vm._v(_vm._s(_vm.$t("ui.appMenu.helpCenter"))),
                ]),
              ]
            ),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }