var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "el-core__header__right" }, [
    _c("div", { staticClass: "emd-drawer _right" }, [
      _c(
        "div",
        { staticClass: "emd-drawer__trigger", class: { _open: _vm.isOpen } },
        [
          _c(
            "button",
            {
              staticClass: "emd-action-button",
              on: { click: _vm.toggleDrawerMenu },
            },
            [
              _c("i", {
                staticClass: "emd-action-button__icon uil uil-ellipsis-v",
                attrs: { transform: "width-6" },
              }),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "emd-drawer__content", class: { _open: _vm.isOpen } },
        [
          _vm.canShowMoreList || _vm.canShowAppsList
            ? _c(
                "div",
                { staticClass: "emd-application" },
                [
                  _c("emd-dropdown", {
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "trigger",
                          fn: function () {
                            return [
                              _c(
                                "div",
                                { staticClass: "emd-application__content" },
                                [
                                  _vm.canShowStatusTag
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "emd-status-tag _color_success",
                                        },
                                        [_c("emd-status-icon")],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("i", {
                                    staticClass:
                                      "emd-application__icon uil uil-apps",
                                    attrs: {
                                      "fixed-width": "",
                                      transform: "width-16",
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "emd-text emd-text _size_section",
                                    },
                                    [_vm._v(" Company ")]
                                  ),
                                  _c("i", {
                                    staticClass:
                                      "emd-application__arrow uil uil-angle-down",
                                    attrs: { transform: "width-14" },
                                  }),
                                ]
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "content",
                          fn: function () {
                            return [
                              _vm.canShowAppsList
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "emd-text _color_default _type_label _size_tiny eu-ma_2",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("apps-menu.title")) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("ul", [
                                      _vm.canShowStatistics
                                        ? _c(
                                            "li",
                                            {
                                              staticClass:
                                                "emd-dropdown__content__item",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "emd-text _color_default",
                                                  attrs: {
                                                    href: _vm.urlFinance,
                                                    target: "_blank",
                                                    rel: "noopener noreferrer",
                                                  },
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "apps-menu.statistics"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                : _vm._e(),
                              _vm.canShowMoreList && _vm.canShowAppsList
                                ? _c("div", {
                                    staticClass:
                                      "emd-divider _weight_base eu-mt_2 eu-mb_3",
                                  })
                                : _vm._e(),
                              _vm.canShowMoreList
                                ? [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "emd-text _color_default _type_label _size_tiny eu-ma_2",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("apps-menu.get-to-know")
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("ul", [
                                      _vm.canShowPix
                                        ? _c(
                                            "li",
                                            {
                                              staticClass:
                                                "emd-dropdown__content__item",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "emd-text _color_default",
                                                  attrs: {
                                                    target: "_blank",
                                                    rel: "noopener noreferrer",
                                                    href: _vm.urlPix,
                                                  },
                                                },
                                                [
                                                  _c("p", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "apps-menu.pix"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                : _vm._e(),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      2895671181
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "emd-dropdown _position_right jsDropdown",
              attrs: { "data-onboarding": "profile" },
            },
            [
              _c("emd-dropdown", {
                attrs: { isOpen: _vm.profileOpen },
                scopedSlots: _vm._u([
                  {
                    key: "trigger",
                    fn: function () {
                      return [
                        _c(
                          "span",
                          [
                            _c(
                              "emd-tippy",
                              {
                                staticClass: "emd-action-button _has-tooltip",
                                attrs: {
                                  id: "profile",
                                  tag: "button",
                                  type: "action-button",
                                  content: _vm.$t("profile"),
                                },
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "emd-action-button__icon uil uil-user",
                                  attrs: {
                                    "fixed-width": "",
                                    transform: "width-16 left-2",
                                  },
                                }),
                                _c("i", {
                                  staticClass:
                                    "emd-action-button__arrow uil uil-angle-down",
                                  attrs: { transform: "width-14" },
                                }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "content",
                    fn: function () {
                      return [
                        _c(
                          "div",
                          { staticClass: "emd-dropdown__content__item" },
                          [
                            _c("span", { staticClass: "emd-badge eu-mt_1" }, [
                              _vm._v(_vm._s(_vm.permission)),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "emd-text _color_default _type_label _size_tiny eu-ma_2",
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("profile")) + " ")]
                            ),
                          ]
                        ),
                        _c("ul", [
                          _c(
                            "li",
                            { staticClass: "emd-dropdown__content__item" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "emd-text _color_default",
                                  attrs: {
                                    href: _vm.profileUrl,
                                    target: "_blank",
                                    rel: "noopener noreferrer",
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "emd-dropdown__content__item__text",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "emd-text _color_nonessential",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "uil uil-user",
                                            attrs: {
                                              "fixed-width": "",
                                              transform: "width-14",
                                            },
                                          }),
                                        ]
                                      ),
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.firstName) +
                                            " " +
                                            _vm._s(_vm.lastName) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "li",
                            { staticClass: "emd-dropdown__content__item" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "emd-text _color_danger",
                                  attrs: { href: "javascript:void(0);" },
                                  on: { click: _vm.triggerLogout },
                                },
                                [
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "emd-dropdown__content__item__text",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "uil uil-sign-out-alt",
                                        attrs: {
                                          "fixed-width": "",
                                          transform: "width-14",
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("profile-menu.logout")
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    },
                    proxy: true,
                  },
                ]),
              }),
            ],
            1
          ),
          _vm.isEmployee
            ? _c(
                "span",
                [
                  _c(
                    "emd-tippy",
                    {
                      class: [
                        "emd-action-button _type_outline _has-tooltip",
                        _vm.backofficeIconClass,
                      ],
                      attrs: {
                        href: _vm.urlBackoffice,
                        id: "backoffice",
                        tag: "a",
                        type: "action-button",
                        content: _vm.$t("backoffice"),
                      },
                    },
                    [_c("span", [_c("emd-pagarme-icon")], 1)]
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }