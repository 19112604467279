var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "el-core _fixed" },
    [
      _c("app-core-header", {
        attrs: { drawerOpen: _vm.drawerMenuOpen, isOpen: _vm.appMenuOpen },
        on: {
          userLoggedOut: _vm.logout,
          appMenuClicked: _vm.toggleAppMenu,
          drawerMenuClicked: _vm.toggleDrawerMenu,
        },
      }),
      _c("section", { staticClass: "el-core__wrapper" }, [
        _c("div", { staticClass: "el-main" }, [
          _c(
            "div",
            {
              staticClass: "el-main__navigation",
              class: { _open: _vm.appMenuOpen },
            },
            [
              _c("app-sidemenu", {
                attrs: { user: _vm.user, isOpen: _vm.appMenuOpen },
                on: { appMenuClicked: _vm.toggleAppMenu },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "el-main__content" },
            [
              _c(
                "div",
                { staticClass: "el-section" },
                [
                  _vm.showNps
                    ? _c("nps", { on: { close: _vm.closeNps } })
                    : _vm._e(),
                  _vm.showGreeting
                    ? _c("greeting", { on: { close: _vm.closeGreeting } })
                    : _vm._e(),
                  _c(
                    "transition",
                    { attrs: { name: _vm.transitionName, mode: "out-in" } },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [_c("emd-footer")],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }