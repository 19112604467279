import dayjs from 'dayjs'
const localizedFormat = require('dayjs/plugin/localizedFormat')
const utc = require('dayjs/plugin/utc')
require('dayjs/locale/pt')

dayjs.extend(localizedFormat)
dayjs.extend(utc)

const formats = {
  date: 'L',
  dateAndHour: 'L - LTS'
}

export function setDateLocale (locale) {
  if (locale) {
    dayjs.locale(locale.substring(0, 2))
  }
}

export function dateFormat (date, format = 'date') {
  if (!date) {
    return '-'
  }

  return dayjs.utc(date).format(formats[format])
}

export function statusClass (status) {
  status = status.toLowerCase()
  return {
    _color_success:
      status === 'active' ||
      status === 'ativo' ||
      status === 'paid' ||
      status === 'pago' ||
      status === 'overpaid' ||
      status === 'paga a maior' ||
      status === 'underpaid' ||
      status === 'paga a menor' ||
      status === 'captured' ||
      status === 'capturado' ||
      status === 'sent' ||
      status === 'enviado' ||
      status === 'approved' ||
      status === 'aprovado' ||
      status === 'parcialmente capturado' ||
      status === 'partial capture' ||
      status === 'none' ||
      status === 'accepted' ||
      status === 'aceito',
    _color_warning:
      status === 'future' ||
      status === 'futuro' ||
      status === 'pending' ||
      status === 'pendente' ||
      status === 'processing' ||
      status === 'processando' ||
      status === 'partial refunded' ||
      status === 'parcialmente estornado' ||
      status === 'parcialmente cancelado' ||
      status === 'partial void' ||
      status === 'generated' ||
      status === 'gerado' ||
      status === 'manual' ||
      status === 'authorized pending capture' ||
      status === 'autorizado captura pendente' ||
      status === 'cartão de crédito pendente' ||
      status === 'pending credit card' ||
      status === 'aguardando cancelamento' ||
      status === 'waiting cancellation' ||
      status === 'aguardando captura' ||
      status === 'waiting capture' ||
      status === 'major' ||
      status === 'pending authentication' ||
      status === 'pendente de autenticação',
    _color_danger:
      status === 'canceled' ||
      status === 'cancelado' ||
      status === 'estornado' ||
      status === 'refunded' ||
      status === 'inactive' ||
      status === 'inativo' ||
      status === 'failed' ||
      status === 'falha' ||
      status === 'deleted' ||
      status === 'deletado' ||
      status === 'with error' ||
      status === 'com erro' ||
      status === 'not paid' ||
      status === 'não pago' ||
      status === 'expired' ||
      status === 'expirado' ||
      status === 'not authorized' ||
      status === 'não autorizado' ||
      status === 'voided' ||
      status === 'negado' ||
      status === 'erro no cancelamento' ||
      status === 'error on voiding' ||
      status === 'erro no estorno' ||
      status === 'error on refunding' ||
      status === 'critical',
    _color_info:
      status === 'gerado' ||
      status === 'generated' ||
      status === 'minor' ||
      status === 'maintenance' ||
      status === 'waiting refund' ||
      status === 'aguardando estorno' ||
      status === 'waiting payment' ||
      status === 'aguardando pagamento'
  }
}

export function pluralIntervals (intervalCount, interval) {
  interval = interval.toLowerCase()
  if (intervalCount > 1) {
    const plurals = [
      {
        dia: 'dias',
        day: 'days',
        semana: 'semanas',
        week: 'weeks',
        mês: 'meses',
        month: 'months',
        ano: 'anos',
        year: 'years'
      }
    ]
    const plural = plurals.find(e => e[interval])
    return plural[interval]
  } else {
    return interval
  }
}