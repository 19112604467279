import { ScopeInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'
import UsersManager from '../../views/Team/Users/user.manager'

export const state = () => ({
  filterOptions: [
    {
      name: 'user.filter.name',
      type: 'text',
      name_query: 'first_name'
    },
    {
      name: 'user.filter.surname',
      type: 'text',
      name_query: 'last_name'
    },
    {
      name: 'user.filter.email',
      type: 'text',
      name_query: 'email'
    }
  ],
  adminUsers: null,
  teamUsers: null,
  user: null
})

export const getters = {
  adminUsers: state => {
    return state.adminUsers ? state.adminUsers : false
  },
  teamUsers: state => {
    return state.teamUsers ? state.teamUsers : false
  },
  userDetail: state => {
    return state.user ? state.user : false
  }
}

export const mutations = {
  setAdminUsers (state, users) {
    state.adminUsers = users
  },
  setTeamUsers (state, users) {
    state.teamUsers = users
  },
  setUser (state, user) {
    state.user = user
  }
}

export const actions = {
  GET_ADMIN_USERS ({ commit, dispatch, getters }, params) {
    const { merchantId } = getters
    commit('setAdminUsers', null)
    params = params.query
      ? Object.assign(params.query, { merchantId })
      : params
    ScopeInstance.user
      .getAllAdmin(params)
      .then(response => {
        commit('setAdminUsers', response.data)
      })
      .catch(err => catchError(err, { commit, dispatch }))
  },
  GET_TEAM_USERS ({ commit, dispatch, getters }, params) {
    const { merchantId } = getters
    commit('setTeamUsers', null)
    params = params.query
      ? Object.assign(params.query, { merchantId })
      : params
    ScopeInstance.user
      .getAllStaff(params)
      .then(response => {
        commit('setTeamUsers', response.data)
      })
      .catch(err => catchError(err, { commit, dispatch }))
  },
  GET_USER ({ commit, dispatch, getters }, { userId }) {
    const { merchantId } = getters
    commit('setUser', null)
    ScopeInstance.user
      .getById({ merchantId, userId })
      .then(response => {
        commit('setUser', new UsersManager([response.data]).getOne())
      })
      .catch(err => catchError(err, { commit, dispatch }))
  },
  GET_USER_ADMIN ({ commit, dispatch, getters }, { userId }) {
    const { merchantId } = getters
    commit('setUser', null)
    ScopeInstance.user
      .getByIdAsAdmin({ merchantId, userId })
      .then(response => {
        commit('setUser', new UsersManager([response.data]).getOne())
      })
      .catch(err => catchError(err, { commit, dispatch }))
  },
  TRANSFER_OWNERSHIP ({ commit, dispatch, getters }, { userId, body }) {
    const { merchantId } = getters
    return ScopeInstance.user
      .transferOwnership(body, { merchantId, userId })
      .then(res => res)
      .catch(err => catchError(err, { commit, dispatch }))
  },
  UPDATE_USER_PERMISSION ({ commit, dispatch, getters }, { userId, body }) {
    const { merchantId } = getters
    return ScopeInstance.user
      .updateMerchantPermission(
        { merchantId, userId: userId },
        body
      )
      .then(response => Promise.resolve(response))
      .catch(err => catchError(err, { commit, dispatch }))
  },
  UPDATE_USER_PERMISSION_ADMIN (
    { commit, dispatch, getters },
    { userId, body }
  ) {
    const { merchantId } = getters
    return ScopeInstance.user
      .updateMerchantPermissionAsAdmin(
        { merchantId, userId: userId },
        body
      )
      .then(response => Promise.resolve(response))
      .catch(err => catchError(err, { commit, dispatch }))
  },
  REMOVE_PERMISSION_STAFF ({ commit, dispatch, getters }, { userId, body }) {
    const { merchantId } = getters
    return ScopeInstance.user
      .removePermissionStaff(body, { merchantId, userId: userId })
      .then(res => Promise.resolve(res))
      .catch(err => catchError(err, { commit, dispatch }))
  },
  REMOVE_PERMISSION_ADMIN ({ commit, dispatch, getters }, { userId, body }) {
    const { merchantId } = getters
    return ScopeInstance.user
      .removePermissionAsAdmin(body, { merchantId, userId: userId })
      .then(res => Promise.resolve(res))
      .catch(err => catchError(err, { commit, dispatch }))
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
