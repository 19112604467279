var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "el-core__header",
      class: { _open: _vm.isOpen, "_drawer-open": _vm.drawerOpen },
    },
    [
      _c("app-core-header-left", {
        attrs: { isOpen: _vm.isOpen },
        on: { appMenuClicked: _vm.toggleAppMenu },
      }),
      _c("app-core-header-right", {
        attrs: {
          merchant: _vm.profile.merchant,
          account: _vm.profile.merchant.account,
          profile: _vm.profile.user,
          isOpen: _vm.drawerOpen,
        },
        on: {
          userLoggedOut: _vm.userLoggedOut,
          drawerMenuClicked: _vm.toggleDrawerMenu,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }