import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
// import { isEmptyObject } from '@/support/helpers.js'
import Motherboard from '@/views/Motherboard.vue'
import Maintenance from '@/components/project/globals/Maintenance.vue'
import { merchantAllowListViewEnabled } from '@/actions/merchant-allowlist.actions'
import {
  settingsInfoGetEnabled,
  settingsGeneralDataViewEnabled,
  settingsFunctionalitiesViewEnabled
} from '@/actions/settings.actions'

import { routeAccounts } from './accounts'
import { routeAdmins } from './admins'
import { routeStaff } from './staff'
import { routeMyPlan } from './myPlan' // hide subscription route
import { routeFinance } from './finance'
import { routeHome } from './home'
import { routeCharges } from './charges.route'

Vue.use(Router)

export default new Router({
  mode: 'history',
  linkActiveClass: '_active',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: [
    {
      path: '/:merchant_id',
      component: Motherboard,
      beforeEnter: (to, from, next) => {
        if (store.getters.maintenance) {
          next({ name: 'maintenance' })
        } else {
          if (!store.getters.userProfile) {
            // eslint-disable-next-line
            const { merchant_id } = to.params
            if (merchant_id.includes('merch_')) {
              const query = { merchant_id }
              store.commit('toggleGlobalLoading', true)
              store.dispatch('GET_MERCHANT_SUMMARY', merchant_id)
              store
                .dispatch('GET_AUTHORIZATION', query)
                .then(() => {
                  store.commit('toggleGlobalLoading', false)
                  next()
                })
                .catch(() => next(false))
            } else {
              next({ name: 'default' })
            }
          } else {
            next()
          }
        }
      },
      children: [
        {
          path: '',
          name: 'motherboard',
          redirect: { name: 'insights' }
        },
        routeHome,
        routeAccounts,
        routeCharges,
        routeAdmins,
        routeStaff,
        routeMyPlan,
        routeFinance,
        {
          path: 'myplan/subscribe/',
          name: 'myPlanSubscribe',
          beforeEnter: (to, from, next) => {
            if (store.getters.permissionIsEnabled('merchant_plan:view')) {
              next()
            } else {
              store.commit('toggleGlobalError', { status: true, code: 404 })
            }
          },
          component: () =>
            import(
              /* webpackChunkName: "subscribe" */ '@/views/MyPlan/Subscribe.vue'
            )
        },
        // hide myPlan route
        {
          path: 'configuration/',
          name: 'configuration',
          beforeEnter: (to, from, next) => {
            const settingsConditions = [
              settingsInfoGetEnabled,
              settingsGeneralDataViewEnabled,
              settingsFunctionalitiesViewEnabled,
              merchantAllowListViewEnabled
            ]

            const hasPermission =
              settingsConditions.some(condition =>
                condition(store.getters.permissionIsEnabled)
              )

            if (!hasPermission) {
              return store.commit('toggleGlobalError', {
                status: true,
                code: 404
              })
            }

            next()
          },
          component: () =>
            import(
              /* webpackChunkName: "configuration" */ '@/views/Configuration/Configuration.vue'
            )
        }
      ]
    },
    {
      path: '/',
      name: 'default',
      component: Motherboard,
      beforeEnter: (to, from, next) => {
        if (store.getters.maintenance) {
          next({ name: 'maintenance' })
        } else {
          store.dispatch('GET_AUTHORIZATION', {}).then(response => {
            if (response) {
              const merchantActiveId = response.id
              if (merchantActiveId) {
                store.commit('toggleGlobalLoading', true)
                store
                  .dispatch('GET_MERCHANT_SUMMARY', merchantActiveId)
                  .then(res => {
                    store.commit('toggleGlobalLoading', false)
                    next({ path: `/${merchantActiveId}/accounts/` })
                  })
              }
            } else {
              // cancel navigation due to 401
              next(false)
            }
          })
        }
      }
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: Maintenance,
      beforeEnter: (to, from, next) => {
        if (store.getters.maintenance) {
          store.commit('toggleGlobalLoading', false)
          next()
        } else {
          next({ name: 'default' })
        }
      }
    },
    {
      path: '*',
      redirect: { name: 'default' }
    }
  ]
})
