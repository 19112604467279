var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeDropdown,
          expression: "closeDropdown",
        },
      ],
      staticClass: "emd-dropdown",
    },
    [
      _c(
        "div",
        {
          staticClass: "emd-dropdown__trigger",
          class: { _active: _vm.isActive },
          on: { click: _vm.toggleDropdown },
        },
        [_vm._t("trigger")],
        2
      ),
      _c(
        "div",
        {
          staticClass: "emd-dropdown__content",
          class: { _active: _vm.isActive },
        },
        [_vm._t("content")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }