import Cookies from 'js-cookie'
import { ProfileTSInstance } from '@/store'
import { catchError } from '@/support/errorLogger.js'
import { setCookieCompany, updateCookieCompany } from '@/support/cookieCompany'
import { identify } from '@fullstory/browser'

import { version } from '@/../package.json'

export const state = () => ({
  profile: undefined,
  type: { key: '', value: '' },
  merchantsCount: null,
  showGreeting: false,
  isNewUser: false
})

const getters = {
  profile (state) {
    return state.profile
  },
  userProfile (state) {
    if (state.profile) {
      return {
        name: `${state.profile.user.first_name} ${state.profile.user.last_name}`,
        email: state.profile.user.email,
        initials: ProfileTSInstance.support.getInitials(
          state.profile.user.first_name,
          state.profile.user.last_name
        )
      }
    } else {
      return state.profile
    }
  },
  isEmployee (state) {
    return state?.profile?.user?.is_root || state?.profile?.user?.is_employee
  },
  userType (state) {
    if (state?.profile?.user?.is_employee) {
      return state.profile.user.employee_type
    } else if (state?.profile?.user?.is_root) {
      return { key: 'root', value: 'root' }
    } else if (state.profile) {
      return state.profile.merchant.type
    }
    return state.type
  },
  userHasMultipleMerchant (state) {
    if (state.profile.user) {
      return state.profile.user.is_root || state.profile.user.is_employee
        ? true
        : state.merchantsCount > 1
    } else {
      return false
    }
  },
  merchantsCount (state) {
    return state.merchantsCount
  },
  merchant (state) {
    if (state.profile) {
      return {
        id: state.profile.merchant.id,
        type: state.profile.merchant.type
      }
    } else {
      return {}
    }
  },
  merchantId (state) {
    return state.profile && state.profile.merchant && state.profile.merchant.id
  },
  showGreeting (state) {
    return state.showGreeting
  },
  isNewUser (state) {
    return state.isNewUser
  }
}

const mutations = {
  setShowGreeting (state, showGreeting) {
    state.showGreeting = showGreeting
  },
  setProfile (state, profile) {
    state.profile = profile
  },
  setIsNewUser (state, isNewUser) {
    state.isNewUser = isNewUser
  },
  setUserProfile (state, profile) {
    state.profile = profile
  },
  setType (state, type) {
    state.type = type
  },
  setMerchantsCount (state, merchantsCount) {
    state.merchantsCount = merchantsCount
  }
}

const actions = {
  GET_AUTHORIZATION ({ dispatch, commit, getters }, params = {}) {
    return ProfileTSInstance.authorization
      .get(params, 'company', process.env.VUE_APP_PROFILE_URL)
      .then(response => {
        commit('setProfile', response.data)
        if (window.newrelic) {
          window.newrelic
            .interaction()
            .setAttribute('id', response.data.user.id)
            .setAttribute(
              'username',
              `${response.data.user.first_name} ${response.data.user.last_name}`
            )
            .setAttribute('email', response.data.user.email)
            .setAttribute(
              'merchant_id',
              response.data.merchant ? response.data.merchant.id : null
            )
        }
        const userVars = {
          id: response.data.user.id,
          displayName: `${response.data.user.first_name} ${response.data.user.last_name}`,
          email: response.data.user.email,
          merchantId_str: response.data.merchant
            ? response.data.merchant.id
            : null,
          merchantName_str: response.data.merchant
            ? response.data.merchant.name
            : null,
          userType_str: response.data.merchant
            ? response.data.merchant.type.key
            : null,
          appVersion_str: version
        }

        if (['production'].includes(process.env.VUE_APP_CORE_ENV))
          identify(response.data.user.id, userVars)

        return response
      })
      .then(response => {
        commit('setMerchantsCount', response.data.user.merchants_count)
        if (response.data.merchant) {
          const merchant = {
            id: response.data.merchant.id,
            name: response.data.merchant.name
          }
          commit('setType', response.data.merchant.type)
          dispatch('SET_MERCHANT_ACTIVE', merchant)
          if (
            !response.data?.user?.is_root &&
            !response.data?.user?.is_employee &&
            (getters.componentIsEnabled('global', 'onboarding') ||
              getters.componentIsEnabled('global', 'nps'))
          ) {
            dispatch('GET_ONBOARDING')
            dispatch('GET_NPS')
          }
          return merchant
        } else {
          return { id: null, name: null }
        }
      })
      .catch(err => catchError(err, { commit, dispatch }))
  },
  GET_ONBOARDING (context) {
    const envAcronym = ProfileTSInstance.envAcronym
    let cookieCompany = Cookies.get(`${envAcronym}mp_company`)

    if (cookieCompany) {
      Promise.resolve(atob(cookieCompany))
        .then(JSON.parse)
        .then(resCookieParse => {
          cookieCompany = resCookieParse
          if (
            cookieCompany.onboarding &&
            Object.entries(cookieCompany.onboarding).length
          ) {
            context.commit(
              'setShowGreeting',
              cookieCompany.onboarding.show_greeting
            )
            context.commit(
              'setIsNewUser',
              Object.keys(cookieCompany.onboarding).includes('is_new_user')
                ? cookieCompany.onboarding.is_new_user
                : cookieCompany.onboarding.show_greeting
            )
          } else {
            return ProfileTSInstance.onboarding
              .get('company')
              .then(res => {
                if (res && Object.entries(res.data).length) {
                  context.commit('setShowGreeting', res.data.show_greeting)
                  context.commit(
                    'setIsNewUser',
                    Object.keys(res.data).includes('is_new_user')
                      ? res.data.is_new_user
                      : res.data.show_greeting
                  )
                } else {
                  context.commit('setShowGreeting', true)
                  context.commit('setIsNewUser', true)
                }
                setCookieCompany({ onboarding: { ...res.data } })
                return Promise.resolve(res.data)
              })
              .catch(err => catchError(err, context))
          }
          return Promise.resolve(cookieCompany.onboarding)
        })
    } else {
      return ProfileTSInstance.onboarding
        .get('company')
        .then(res => {
          if (res && Object.entries(res.data).length) {
            context.commit('setShowGreeting', res.data.show_greeting)
            context.commit(
              'setIsNewUser',
              Object.keys(res.data).includes('is_new_user')
                ? res.data.is_new_user
                : res.data.show_greeting
            )
          } else {
            context.commit('setShowGreeting', true)
            context.commit('setIsNewUser', true)
          }
          setCookieCompany({ onboarding: { ...res.data } })
          return Promise.resolve(res.data)
        })
        .catch(err => catchError(err, context))
    }
  },
  UPDATE_GREETING (context, showGreeting) {
    context.commit('setShowGreeting', showGreeting)
    updateCookieCompany('onboarding')
    return ProfileTSInstance.onboarding.update('company', {
      show_greeting: context.state.showGreeting,
      is_new_user: context.state.isNewUser
    })
  },
  DELETE_AUTHORIZATION ({ dispatch }, logout = false) {
    dispatch('TOGGLE_GLOBAL_LOADING', true)
    return ProfileTSInstance.authorization.deleteCookies('company', logout)
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
